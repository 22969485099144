import { render, staticRenderFns } from "./appInfo.vue?vue&type=template&id=447f0b72&scoped=true&"
import script from "./appInfo.vue?vue&type=script&lang=js&"
export * from "./appInfo.vue?vue&type=script&lang=js&"
import style0 from "./appInfo.vue?vue&type=style&index=0&id=447f0b72&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447f0b72",
  null
  
)

export default component.exports